import React, {useState} from 'react';
import {useCookies} from 'react-cookie';
import {Toast, Tooltip} from "flowbite-react";
import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import logo from './images/iconBlack.png';
import bg from './images/bg.svg';
import './index.css';

function App() {

    const [showToast, setShowToast] = useState(true);
    const [cookies, setCookie] = useCookies(['access_token', 'refresh_token'])

    function acceptCookies() {
        let expires = new Date();
        expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
        setCookie('access_token', true, {path: '/', sameSite: 'none', secure: true, expires});
        setShowToast(false);
    }

    return (
        <div className="flex flex-col h-screen">
            <nav className="flex flex-wrap items-center justify-between sm:w-auto max-w-screen-lg mx-auto py-3">
                <div className="items-start">
                    <a href="/" className="float-left">
                        <img src={logo} alt="..."/>
                        <span
                            className="inline text-lg font-medium whitespace-nowrap dark:text-white uppercase">RgFly</span>
                    </a>
                </div>
            </nav>
            <section className="bg-white dark:bg-dark mb-auto">
                <div className="flex flex-wrap items-center justify-between sm:w-auto max-w-screen-lg mx-auto">
                    <div
                        className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center justify-between gap-1">
                        <div className="w-full px-4">
                            <div className="flex items-center sm:-mx-4">
                                <div className="w-full z-10 px-3 sm:px-4">
                                    <div className="py-3 sm:py-4">
                                        <img src={image1} alt="..."/>
                                    </div>
                                    <div className="py-3 sm:py-4">
                                        <img src={image2} alt="..."/>
                                    </div>
                                </div>
                                <div className="w-full px-3 sm:px-4">
                                    <div className="relative z-10 my-4">
                                        <img src={image3} alt="..."/>
                                        <span className="absolute -top-[200px] -left-[12px] z-[-1] mt-2">
                                        <img src={bg} alt="..."/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4">
                            <div className="mt-10 lg:mt-0">
                               <span className="block mb-4 text-lg font-semibold text-primary">
                                   Make your customers happy by giving services
                               </span>
                                <h2 className="mb-5 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]">
                                    Helping businesses deliver exceptional buyer experiences.
                                </h2>
                                <p className="mb-5 text-base text-body-color dark:text-dark-6">
                                    It is a long-established fact that a reader will be distracted
                                    by the readable content of a page when looking at its layout.
                                    The point of using Lorem Ipsum is that it has a more-or-less.
                                </p>
                                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                                    We believe not just in growing bigger, but in growing better. And growing better
                                    means
                                    aligning
                                    the success of your own business with the success of your customers. Win-win!
                                </p>
                                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                                    We help businesses transform their customer experiences to increase trust,
                                    efficiency,
                                    satisfaction, and loyalty.
                                </p>
                                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                                    We believe risk should never keep you from growing your business with confidence.
                                </p>
                                <a href="mailto:alexander@rgfly.site"
                                   className="inline-flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showToast && !cookies.access_token && (
                <Toast>
                    <div id="cookie-alert" className="absolute bottom-4 right-4 w-64">
                        <div className="bg-gray-50 bg-opacity-90 rounded-lg shadow-lg p-4">
                            <div className="flex items-center justify-between mb-2">
                                <div className="flex items-center">
                                    <img src="https://www.svgrepo.com/show/401340/cookie.svg" alt="Cookie"
                                         className="h-6 w-6 mr-2"/>
                                    <span className="text-gray-700 font-bold text-sm">Cookie Policy</span>
                                </div>
                                <Toast.Toggle/>
                            </div>
                            <p className="text-gray-600 text-sm">
                                We use cookies to enhance your experience. By continuing to visit this site, you
                                agree to our use of cookies.
                                <a className="underline text-green-700 hover:no-underline" href="javascript:void(0)">cookie
                                    policy</a>.
                            </p>
                            <button id="accept-cookie"
                                    onClick={acceptCookies}
                                    className="mt-4 bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
                                Accept
                            </button>
                        </div>
                    </div>
                </Toast>
            )}
            <footer className="bg-white p-5 dark:bg-gray-800 antialiased">
                <p className="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 sm:mb-0">
                    &copy; 2008-2024 <a href="/" className="hover:underline">Rgfly.site</a>.
                    All
                    rights reserved.
                </p>
                <div className="flex justify-center items-center space-x-1">
                    <Tooltip content="Facebook" animation="duration-1000">
                        <a href="https://www.facebook.com/AlexanderShtyher/" target="_blank" rel="noopener noreferrer"
                           className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor"
                                 viewBox="0 0 8 19">
                                <path fillRule="evenodd"
                                      d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                      clipRule="evenodd"/>
                            </svg>
                        </a>
                    </Tooltip>
                    <Tooltip content="Limked In" animation="duration-1000">
                        <a href="https://www.linkedin.com/in/alexander-shtykher-4822b339/" target="_blank"
                           rel="noopener noreferrer"
                           data-tooltip-target="tooltip-linkedin"
                           className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                 height="24"
                                 fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd"
                                      d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                                      clipRule="evenodd"/>
                                <path d="M7.2 8.809H4V19.5h3.2V8.809Z"/>
                            </svg>
                        </a>
                    </Tooltip>
                    <Tooltip content="GitHub" animation="duration-1000">
                        <a href="https://github.com/alexfer" data-tooltip-target="tooltip-github" target="_blank"
                           rel="noopener noreferrer"
                           className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-600">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path fillRule="evenodd"
                                      d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
                                      clipRule="evenodd"/>
                            </svg>
                        </a>
                    </Tooltip>
                </div>
            </footer>
        </div>
    );
}

export default App;
